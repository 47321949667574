<template lang="pug">
v-app(id="app")
  div(v-if="$auth.isAuth()")
    v-navigation-drawer(
      v-model="drawer"
      app
      permanent
      mini-variant-width="56"
      width="350"
      :light="menuTheme === 'light'"
      :dark="menuTheme === 'dark'"
    )
      template(#prepend)
        div.h-60
        //- Logo.pt-5.pb-4.pl-6.pr-5

      Menu(:active="activePage.name")
    div(id="content-area" style="overflow: 'hidden'").content-area-reduced
      div(id="content-overlay")
      div.relative
        TopBar(:active="activePage.name")
        div.content-wrapper
          div.router-view
            router-view
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Marad',
  components: {
    Menu: () => import('@/components/molecules/Menu/Menu.vue'),
    TopBar: () => import('@/components/molecules/TopBar/TopBar.vue'),
    Logo: () => import('@/components/molecules/LogoApp')

  },
  data () {
    return {
      menuTheme: 'light',
      drawer: null
    }
  },
  computed: {
    ...mapState({
      activePage: state => state.main.activePage
    })
  }
}
</script>
<style scoped>
::v-deep ::-webkit-scrollbar {
  width: 0;
  background: transparent
}

</style>
